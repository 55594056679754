import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const PageHeaderBox = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.pageHeader};
  font-weight: 600;
  position: relative;
  width: 100%;
  margin-top: 0;
  &::after {
    content: "";
    width: 100px;
    height: 2px;
    background-color: ${({ lineColor, theme }) => (lineColor ? lineColor : theme.colors.black)};
    position: absolute;
    transition: 0.2s ease;
    top: 55px;
    left: 0;
  }
  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.fontSize.pageHeaderSmall};
  }
`

const PageHeader = ({ children, lineColor }) => {
  return (
    <PageHeaderBox data-aos="fade-right" data-aos-duration="800" lineColor={lineColor}>
      {children}
    </PageHeaderBox>
  )
}

PageHeader.propTypes = {
  children: PropTypes.string,
  lineColor: PropTypes.string,
  animation: PropTypes.bool,
}

export default PageHeader
