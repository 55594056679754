import styled from "styled-components"

const Paragraph = styled.p`
  font-weight: 200;
  font-size: ${({ theme }) => theme.fontSize.medium};
  @media (max-width: 1279px) {
    font-size: 1.3rem;
  }
`

export default Paragraph
