import styled from "styled-components"

const ParagraphSmall = styled.p`
  font-weight: 100;
  font-size: ${({ theme }) => theme.fontSize.small};
  @media (max-width: 1279px) {
    font-size: ${({ theme }) => theme.fontSize.medium};
    line-height: 27px;
  }
`

export default ParagraphSmall
